import {
  PrismicImage,
  PrismicPageDefinition,
  PrismicRichText,
  PrismicExternalLink,
} from "./types";

export type TypeOurReviewsG2Grid = {
  iframe_heading: string;
  g2_iframe: PrismicRichText;
  g2_item_link: PrismicRichText;
};

export type TypeOurReviewsPage = {
  title: string;
  meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
  hero_image: PrismicImage;
  hero_text: PrismicRichText;
  hero_subtitle: PrismicRichText;

  about_heading: string;
  about_subheading: string;

  g2_reviews_heading: string;
  g2_reviews_subheading: string;
  g2_reviews_iframe: PrismicRichText;
  g2_more_reviews_link_text: string;
  g2_more_reviews_link: string;

  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  g2_grid_group: TypeOurReviewsG2Grid[];
};

export type TypeOurReviewsQueryResult = {
  allPrismicG2Page: {
    edges: Array<{
      node: { data: TypeOurReviewsPage };
    }>;
  };
};

export const getPage = (
  data: TypeOurReviewsQueryResult
): TypeOurReviewsPage => {
  return data.allPrismicG2Page.edges[0].node.data;
};

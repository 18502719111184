import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./our-reviews.scss";
import {
  getPage,
  TypeOurReviewsPage,
  TypeOurReviewsQueryResult,
} from "../../utils/queries/g2_page";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";
const query = graphql`
  {
    allPrismicG2Page {
      edges {
        node {
          id
          data {
            g2_reviews_iframe {
              html
              raw
              text
            }
            about_heading
            about_subheading {
              html
              raw
              text
            }
            cta_button_text
            cta_text
            g2_grid_group {
              g2_iframe {
                html
                raw
                text
              }
              g2_item_link {
                html
                raw
                text
              }
              iframe_heading
            }
            g2_more_reviews_link
            g2_more_reviews_link_text
            g2_reviews_heading
            g2_reviews_subheading
            hero_image {
              alt
              url
            }
            hero_subtitle {
              html
              raw
              text
            }
            hero_text {
              html
              raw
              text
            }
            page_meta_description
            page_meta_thumbnail {
              alt
              url
            }
            second_cta_button_text
            title
            meta_title
            second_cta_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const OurReviewsPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeOurReviewsQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicG2Page",
    true
  ) as TypeOurReviewsPage;
  const title = result.meta_title ? result.meta_title : result.title;

  console.log({ resss: result.g2_grid_group })
  return (
    <AmazeePage location={location}>
      <div id="our-reviews">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.hero_subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="about-g2">
          <div className="container">
            <div className="row flex justify-center items-center">
              <div className="col-12 col-lg-8 text-center">
                <h2>{RichTextRender(result.about_heading)}</h2>
                <div className="mb-16">{RichTextRender(result.about_subheading)}</div>
              </div>
            </div>
            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        {result.g2_grid_group.length > 0 && (
          <section id="reviews">
            <div className="container col-12 col-lg-6">
              <div className="row justify-center flex">
                {result.g2_grid_group.map((gridItem, index) => (
                  <div className="review" key={index}>
                    <h3 className="review-heading">
                      {gridItem.iframe_heading}
                    </h3>
                    <div className="review-wrap">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: gridItem.g2_iframe.text,
                        }}
                      />
                    </div>
                    <div className="review-link">
                      {RichTextRender(gridItem.g2_item_link)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}

        <section id="bottom-reviews">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <h2>{result.g2_reviews_heading}</h2>
                <p>{RichTextRender(result.g2_reviews_subheading)}</p>
              </div>
            </div>
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 review-holder">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: result.g2_reviews_iframe.text,
                  }}
                />
                <div className="more-reviews-link a-button">
                  <a
                    className="external"
                    href={result.g2_more_reviews_link}
                    target="_blank"
                  >
                    {result.g2_more_reviews_link_text}
                  </a>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <ResourceInteruption
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          second_cta_button_text={result.second_cta_button_text}
          cta_link={result.cta_link}
          second_cta_button_link={result.second_cta_button_link}
          topMargin={false}
        />
      </div>
    </AmazeePage>
  );
};

const StaticTypeOurReviewsPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <OurReviewsPage location={location} data={data} />}
    />
  );
};
export default StaticTypeOurReviewsPage;
